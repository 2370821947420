import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Select, Divider, Row, Col, Spin } from "antd";
import { getCountries } from "redux/selectors/countries";
import {
  loadCountries,
  loadCountry,
  loadCountryRates,
  setEditRates,
  updateCountryRate,
  saveCountryRates,
} from "redux/ducks/countries";
import { CountryRatesType } from "types/rates";
import { isEmpty, get } from "lodash";
import EditablePage from "components/EditablePage";
import LabeledInputWithUnits from "components/LabeledInputWithUnits";
import CountryType from "types/country";

import "./style.less";

const { Option } = Select;

class CountryRates extends Component {
  static propTypes = {
    rates: CountryRatesType,
    unsavedRateFields: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    country: CountryType.isRequired,
    countries: PropTypes.arrayOf(CountryType).isRequired,
    loadCountries: PropTypes.func.isRequired,
    loadCountry: PropTypes.func.isRequired,
    loadCountryRates: PropTypes.func.isRequired,
    setEditRates: PropTypes.func.isRequired,
    updateCountryRate: PropTypes.func.isRequired,
    saveCountryRates: PropTypes.func.isRequired,
    saveError: PropTypes.string.isRequired,
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
  };

  static defaultProps = {
    rates: {},
  };

  componentDidMount() {
    const countryID = localStorage.getItem("CountryID");
    if (countryID) {
      this.props.loadCountry(countryID);
      this.props.loadCountryRates(countryID);
    }
    this.props.loadCountries();

    const countryRateTaskVariables = get(this.props.location, "state.rateVariables", []);

    if (countryRateTaskVariables.length > 0) {
      this.props.setEditRates(true);
    }
  }

  handleCountrySelect = value => {
    this.props.loadCountry(value);
    this.props.loadCountryRates(value);
  };

  handleRatesEdit = () => {
    this.props.setEditRates(true);
  };

  handleCancelRatesEdit = () => {
    this.props.setEditRates(false);
  };

  handleChangeField = key => value => {
    this.props.updateCountryRate(key, value);
  };

  handleRatesSave = () => {
    const { unsavedRateFields, country } = this.props;
    if (!isEmpty(unsavedRateFields)) {
      this.props.saveCountryRates(country.CountryID, unsavedRateFields);
    } else {
      this.props.setEditRates(false);
    }
  };

  highlightTaskVariableField = key => {
    // when navigating from the task page, we will provide a list of attributes to highlight.
    const countryRateTaskVariables = get(this.props.location, "state.rateVariables", []);

    return countryRateTaskVariables.includes(key) ? "highlight-field" : "";
  };

  render() {
    const { countries, country, rates, isEditing, saveError } = this.props;
    const breadcrumbs = [{ title: "Settings" }, { title: "Country Rates" }];

    if (isEmpty(rates)) {
      return <Spin spinning={true} />;
    }

    return (
      <EditablePage
        breadcrumbs={breadcrumbs}
        isEditing={isEditing}
        isEditable={true}
        saveError={saveError}
        onCancelEdit={this.handleCancelRatesEdit}
        onEdit={this.handleRatesEdit}
        onSave={this.handleRatesSave}
      >
        <div className="country-rates-content">
          <div className="country-rates-content-title">
            <h1>Country Rates for:</h1>
            <Select
              disabled={isEditing}
              className="country-select"
              value={country.CountryID}
              onSelect={this.handleCountrySelect}
            >
              {countries.map(c => (
                <Option key={c.CountryID} value={c.CountryID}>
                  {c.Name}
                </Option>
              ))}
            </Select>
          </div>
          <Divider />
          <h2>Labor and Engineering</h2>
          <Row type="flex">
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Labor:"
                min={0}
                onChange={this.handleChangeField("laborRate")}
                className={this.highlightTaskVariableField("laborRate")}
                value={rates.laborRate}
              />
            </Col>
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Shift Duration:"
                min={0}
                onChange={this.handleChangeField("shiftDuration")}
                className={this.highlightTaskVariableField("shiftDuration")}
                value={rates.shiftDuration}
              />
            </Col>
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Engineering Rate:"
                min={0}
                onChange={this.handleChangeField("engineeringRate")}
                className={this.highlightTaskVariableField("engineeringRate")}
                value={rates.engineeringRate}
              />
            </Col>
          </Row>
          <h2>Support and Supervision</h2>
          <div className="support-supervision-table">
            <Row className="header-row" type="flex">
              <Col span={4} />
              <Col span={8}>Pre P&A</Col>
              <Col span={8}>Post P&A</Col>
            </Row>
            <Row type="flex">
              <Col className="row-label" span={4}>
                Direct Support
              </Col>
              <Col span={8}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("directSupportPrePA")}
                  className={this.highlightTaskVariableField("directSupportPrePA")}
                  value={rates.directSupportPrePA}
                />
              </Col>
              <Col span={8}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("directSupportPostPA")}
                  className={this.highlightTaskVariableField("directSupportPostPA")}
                  value={rates.directSupportPostPA}
                />
              </Col>
            </Row>
            <Row type="flex">
              <Col className="row-label" span={4}>
                Supervision
              </Col>
              <Col span={8}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("supervisionPrePA")}
                  className={this.highlightTaskVariableField("supervisionPrePA")}
                  value={rates.supervisionPrePA}
                />
              </Col>
              <Col span={8}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("supervisionPostPA")}
                  className={this.highlightTaskVariableField("supervisionPostPA")}
                  value={rates.supervisionPostPA}
                />
              </Col>
            </Row>
          </div>
          <h2>Productivity</h2>
          <Row type="flex">
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="DFPV:"
                min={0}
                onChange={this.handleChangeField("productivityDFPV")}
                className={this.highlightTaskVariableField("productivityDFPV")}
                value={rates.productivityDFPV}
              />
            </Col>
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Topsides Decom:"
                min={0}
                onChange={this.handleChangeField("productivityTopsidesDecom")}
                className={this.highlightTaskVariableField("productivityTopsidesDecom")}
                value={rates.productivityTopsidesDecom}
              />
            </Col>
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Substructure Prep:"
                min={0}
                onChange={this.handleChangeField("productivitySubstructurePrep")}
                className={this.highlightTaskVariableField("productivitySubstructurePrep")}
                value={rates.productivitySubstructurePrep}
              />
            </Col>
          </Row>
          <Row type="flex">
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Topsides Prep:"
                min={0}
                onChange={this.handleChangeField("productivityTopsidesPrep")}
                className={this.highlightTaskVariableField("productivityTopsidesPrep")}
                value={rates.productivityTopsidesPrep}
              />
            </Col>
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="R&R:"
                min={0}
                onChange={this.handleChangeField("productivityRR")}
                className={this.highlightTaskVariableField("productivityRR")}
                value={rates.productivityRR}
              />
            </Col>
          </Row>
          <h2>Equipment and Logistics</h2>
          <Row type="flex">
            <Col span={12}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Piece Small Removal EQ:"
                min={0}
                onChange={this.handleChangeField("pieceSmallEquipmentRate")}
                className={this.highlightTaskVariableField("pieceSmallEquipmentRate")}
                value={rates.pieceSmallEquipmentRate}
              />
            </Col>
            <Col span={12}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Preparation EQ - Multi Lift:"
                min={0}
                onChange={this.handleChangeField("multipleLiftPreparationEquipmentRate")}
                className={this.highlightTaskVariableField("multipleLiftPreparationEquipmentRate")}
                value={rates.multipleLiftPreparationEquipmentRate}
              />
            </Col>
          </Row>
          <Row type="flex">
            <Col span={12}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Logistics - Platform:"
                min={0}
                onChange={this.handleChangeField("logisticsRate")}
                className={this.highlightTaskVariableField("logisticsRate")}
                value={rates.logisticsRate}
              />
            </Col>
            <Col span={12}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Preparation EQ - Single Lift:"
                min={0}
                onChange={this.handleChangeField("singleLiftPreparationEquipmentRate")}
                className={this.highlightTaskVariableField("singleLiftPreparationEquipmentRate")}
                value={rates.singleLiftPreparationEquipmentRate}
              />
            </Col>
          </Row>
          <h2>Materials</h2>
          <Row type="flex">
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Fabrication Rate:"
                min={0}
                onChange={this.handleChangeField("fabricationRate")}
                className={this.highlightTaskVariableField("fabricationRate")}
                value={rates.fabricationRate}
              />
            </Col>
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Disposal Rate:"
                min={0}
                onChange={this.handleChangeField("disposalRate")}
                className={this.highlightTaskVariableField("disposalRate")}
                value={rates.disposalRate}
              />
            </Col>
          </Row>
          <h2>Vessel Rates</h2>
          <div className="vessel-rates-table">
            <Row className="header-row" type="flex">
              <Col span={2} />
              <Col span={5}>500 Te</Col>
              <Col span={5}>1500 Te</Col>
              <Col span={5}>5000 Te</Col>
              <Col span={5}>14000 Te</Col>
            </Row>
            <Row type="flex">
              <Col className="row-label" span={2}>
                Barge Rate
              </Col>
              <Col span={5}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("bargeRate_500Te")}
                  className={this.highlightTaskVariableField("bargeRate_500Te")}
                  value={rates.bargeRate_500Te}
                />
              </Col>
              <Col span={5}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("bargeRate_1500Te")}
                  className={this.highlightTaskVariableField("bargeRate_1500Te")}
                  value={rates.bargeRate_1500Te}
                />
              </Col>
              <Col span={5}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("bargeRate_5000Te")}
                  className={this.highlightTaskVariableField("bargeRate_5000Te")}
                  value={rates.bargeRate_5000Te}
                />
              </Col>
              <Col span={5}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("bargeRate_14000Te")}
                  className={this.highlightTaskVariableField("bargeRate_14000Te")}
                  value={rates.bargeRate_14000Te}
                />
              </Col>
            </Row>
            <Row type="flex">
              <Col className="row-label" span={2}>
                Tug Rate
              </Col>
              <Col span={5}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("tugRate_500Te")}
                  className={this.highlightTaskVariableField("tugRate_500Te")}
                  value={rates.tugRate_500Te}
                />
              </Col>
              <Col span={5}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("tugRate_1500Te")}
                  className={this.highlightTaskVariableField("tugRate_1500Te")}
                  value={rates.tugRate_1500Te}
                />
              </Col>
              <Col span={5}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("tugRate_5000Te")}
                  className={this.highlightTaskVariableField("tugRate_5000Te")}
                  value={rates.tugRate_5000Te}
                />
              </Col>
              <Col span={5}>
                <LabeledInputWithUnits
                  isEditable={isEditing}
                  min={0}
                  onChange={this.handleChangeField("tugRate_14000Te")}
                  className={this.highlightTaskVariableField("tugRate_14000Te")}
                  value={rates.tugRate_14000Te}
                />
              </Col>
            </Row>
          </div>
          <Row type="flex">
            <Col span={8}>
              <LabeledInputWithUnits
                isEditable={isEditing}
                label="Supply Boat:"
                min={0}
                onChange={this.handleChangeField("supplyBoatRate")}
                className={this.highlightTaskVariableField("supplyBoatRate")}
                value={rates.supplyBoatRate}
              />
            </Col>
          </Row>
        </div>
      </EditablePage>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadCountries: () => dispatch(loadCountries()),
    loadCountry: countryID => dispatch(loadCountry(countryID)),
    loadCountryRates: countryID => dispatch(loadCountryRates(countryID)),
    setEditRates: isEditing => dispatch(setEditRates(isEditing)),
    updateCountryRate: (key, value) => dispatch(updateCountryRate(key, value)),
    saveCountryRates: (id, rates) => dispatch(saveCountryRates(id, rates)),
  };
}

function mapStateToProps({ countries }) {
  return {
    countries: getCountries(countries),
    country: countries.country,
    rates: countries.rates,
    unsavedRateFields: countries.unsavedRateFields,
    isEditing: countries.isEditing,
    saveError: countries.saveError,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CountryRates)
);
